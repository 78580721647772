import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux'
import { getCompanyQuestions } from '../features/company'

import LoadingSpinner from "../components/LoadingSpinner";
import Wellcome from "../components/Wellcome";
import QuizInfo from "../components/QuizInfo";
import Questions from "../components/Questions";
import EndQuizScreen from "../components/EndQuiz";
import { useNavigate } from "react-router-dom";

const CompanyTest = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();

    const companyName = useSelector(state => state.company.companyName);
    const companyEmail = useSelector(state => state.company.companyEmail);
    const questions = useSelector(state => state.company.questions);

    const { permalink } = useParams();

    const [isLoading, setLoading] = useState(false)
    const [wellcomeSection, setWellcomeSection] = useState(true);
    const [showInfo, setShowInfo] = useState(false);
    const [showQuestions, setShowQuestions] = useState(false);
    const [endQuiz, setEndQuiz] = useState(false);

    useEffect(() => {
        dispatch(getCompanyQuestions({ permalink: permalink })).then(p => {
            if(p.payload != undefined && p.payload == 'error'){
                navigation('*');
            }
        })
    }, [permalink]);

    return (
        <div className="home">
            {isLoading ? 
                <LoadingSpinner />:
                <>
                    {wellcomeSection && companyEmail != null ? 
                        <Wellcome 
                            companyName={companyName}
                            companyEmail={companyEmail}
                            setWellcomeSection={setWellcomeSection}
                            setShowInfo={setShowInfo}
                            setLoading={setLoading}
                        /> : null
                    }
                    {showInfo ?
                        <QuizInfo 
                            questionNumber={questions.length}
                            setShowInfo={setShowInfo}
                            setLoading={setLoading}
                            setShowQuestions={setShowQuestions}
                        /> : null
                    }
                    {showQuestions ?
                        <Questions
                            questions={questions}
                            setEndQuiz={setEndQuiz}
                            setShowQuestions={setShowQuestions}
                        />
                        :  null
                    }
                    {endQuiz ? 
                        <EndQuizScreen 

                        /> 
                        : null}
                </>
            }
        </div>
    );
}

export default CompanyTest;