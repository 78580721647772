import { useEffect } from "react";

const QuizInfo = ({questionNumber, setShowInfo, setLoading, setShowQuestions}) => {

    const startQuiz = () => {
        setLoading(true);
        setTimeout(setLoading(false), 1000)
        setShowInfo(false);
        setShowQuestions(true);
    }

    return (
        <div className="quiz-info">
            <p>You must have a microphone and a reliable internet connection.</p>
            <p>It is recommended to take this test from a quiet place.</p>
            <h1>You will see {questionNumber} questions. Answer all of them. Good luck!</h1>
            
            <div className="start-test-button">
                <a className="start-test-button" onClick={() => startQuiz()}>NEXT</a>
            </div>
        </div>
    );
}

export default QuizInfo;