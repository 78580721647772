import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import API from "../api"

export const addUser = createAsyncThunk(
    "user/addUser",
    async (dispatch, thunkAPI) => {
        try {
            const result = await API.post(`users` ,{
                "email": dispatch.email,
                "firstName": dispatch.fname,
                "lastName": dispatch.lname,
                "companyEmail": dispatch.companyEmail
            });
            if(result.status != 200){
            return thunkAPI.rejectWithValue(null);
            }
            return result.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(null)
        }
    }
)

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: [],
        loading: false,
        error: null,
    },
    extraReducers: {
        [addUser.pending]: (state, action) => {
            state.loading = true
        },
        [addUser.fulfilled]: (state, action) => {
            state.loading = false
            state.user = action.payload
        },
        [addUser.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export default userSlice.reducer