import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import API from "../api"

export const getCompanyQuestions = createAsyncThunk(
    "company/getCompanyQuestions",
    async (dispatch, thunkAPI) => {
        try {
            return API.get(`companies/${dispatch.permalink}/questions`)
            .then((response) => {
                if (response.status === 200) {
                    return response.data
                } else {
                    return thunkAPI.rejectWithValue('error')
                }
            })
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

export const companySlice = createSlice({
    name: "company",
    initialState: {
        companyName: "",
        companyEmail: "",
        questions: [],
        loading: false,
        error: null,
    },
    extraReducers: {
        [getCompanyQuestions.pending]: (state, action) => {
            state.loading = true
        },
        [getCompanyQuestions.fulfilled]: (state, action) => {
            state.loading = false
            state.questions = action.payload.Questions
            state.companyName = action.payload.name
            state.companyEmail = action.payload.email
        },
        [getCompanyQuestions.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export default companySlice.reducer