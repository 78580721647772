import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { addUser } from '../features/user'

const Wellcome = ({companyName, companyEmail, setWellcomeSection, setLoading, setShowInfo}) => {

    const dispatch = useDispatch()

    const [errorForm, setError] = useState();
    const [formData, setFormData] = useState({
        email: "",
        fname: "",
        lname: ""
    })    

    useEffect (() => {
        setLoading(false);
    } , [])

    const validator = (errorForm) => {
        function emailIsValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        }
        setError('');
        if (formData.fname.length < 2) {
            setError('Please enter your first name');
            return false
        }
        if (formData.lname.length < 2) {
            setError('Please enter your last name');
            return false
        }
        if (!emailIsValid(formData.email)) {
            setError('Please enter a valid email');
            return false
        }
        return true
    }    

    const startQuiz = () => {
        if (validator(errorForm)) {
            setLoading(true);
            const obj = Object.assign({},formData,{companyEmail});
            dispatch(addUser(obj)).then((response) => {
                setLoading(false)
                if (response.payload) {
                    setWellcomeSection(false);
                    setShowInfo(true);
                } else {
                    setTimeout(()=> {
                        alert('Something went wrong, please try again');
                    },400)
                }
            });
        }
    }


    return (
        <div className="wellcome">
            <h1>Wellcome to {companyName}'s Language Competence Evaluation</h1>
            <form className="login-form">
                <div className="form-group">
                    <label htmlFor="InputEmail1">Email address</label>
                    <input
                        type="email"
                        className="form-control" 
                        id="InputEmail1" 
                        aria-describedby="emailHelp" 
                        placeholder="Enter email"
                        value={formData.email}
                        onChange={(e) => setFormData({...formData, email: e.target.value})}
                    />

                </div>
                <div className="form-group">
                    <label htmlFor="inputFName">First Name</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="inputFName"
                        placeholder="Enter first name"
                        value={formData.fname}
                        onChange={(e) => setFormData({...formData, fname: e.target.value})}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="inputLName">Last Name</label>
                    <input 
                        type="text"
                        className="form-control"
                        id="inputLName"
                        placeholder="Enter last name"
                        onChange={(e) => setFormData({...formData, lname: e.target.value})}
                    />
                </div>
            </form>
            <p className="form-error-msg">{errorForm}</p>
            <div className="terms">
                <p>
                By starting this test you agree to the <a href="https://worddio.com/worddio-evaluate-terms-conditions/" target="_blank">terms and conditions</a> and <a href="https://worddio.com/worddio-evaluate-privacy-policy/" target="_blank">privacy policy</a> of Worddio Evaluate.
                </p>
            </div>
            <div className="start-test-button">
                <a className="start-test-button" onClick={() => startQuiz()}>Start test</a>
            </div>
        </div>
    );
}

export default Wellcome;