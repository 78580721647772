import axios from 'axios';

const getAPIURL = () => {
  const isProd = true;
  return isProd ? `https://evaluate.worddio.com/api/` : `http://localhost:3000/api/`
}

const API = axios.create({
  baseURL: getAPIURL(),
  headers: {
    'Content-Type': 'application/json',
  }
});


API.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 404) {
    console.log(error);
  }
 return error;
});

export default API