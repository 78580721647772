
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';

import store from './store';

import Navbar from './components/Navbar';

import Home from './pages/Home';
import CompanyTest from './pages/CompanyTest';

import PageNotFound from './pages/PageNotFound';

let persistor = persistStore(store);

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Navbar />
            <div className="pages">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/company/:permalink" element={<CompanyTest />} />

                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
