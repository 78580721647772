import React, { useState, useEffect } from 'react';

import AudioReactRecorder, { RecordState } from 'audio-react-recorder'

// const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Recorder = ({ setBlob, blobURLInternal, setBlobInternalURL }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [recordState, setRecordState] = useState(null);
    const [isBlocked, setIsBlocked] = useState(false);
    // const [blobURLInternal , setBlobInternalURL] = useState(null);

    const checkPermission = () => {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(stream => {
                window.localStream = stream; // A
                setIsBlocked(false);
            }).catch(err => {
                console.log(err);
                setIsBlocked(true);
            });
    }

    const start = () => {
        if (isBlocked) {
            alert('Permission Denied, please refresh the page and give microphone permission');
        } else {
            setIsRecording(true)
            setRecordState(RecordState.START)
        }
    };

    const stop = () => {
        setIsRecording(false)
        setRecordState(RecordState.STOP)
    };

    const onStop = (data) => {
        setBlob(data.blob)
        setBlobInternalURL(data.url)
    }

    useEffect(() => {
        checkPermission();
    }, []);

    return (
        <div className="recorder">
            <div className="recorder_controls">
                <AudioReactRecorder
                    state={recordState}
                    onStop={onStop}
                    backgroundColor="#10afb5"
                    foregroundColor="#ffffff"
                />
                {isRecording ?
                    <>
                        <button onClick={() => stop()} className={`circle_button ${isRecording ? "active Rec" : ""}`} disabled={!isRecording} >
                            <i className="fas fa-stop-circle"></i> <a>Stop</a>
                        </button>
                    </>
                    :
                    <button onClick={() => start()} className={`circle_button ${!isRecording ? "active" : "Rec"}`} disabled={isRecording} >
                        <i className="fas fa-microphone-alt"></i> 
                        { !blobURLInternal ? 
                            <a>Record</a>
                        :   <a>Re-record</a> }
                    </button>
                }
            </div>
            {blobURLInternal ?
                <div className="recorder_player">
                    <audio src={blobURLInternal} controls="controls" />
                </div>
                :
                <>
                    <p className='please_make_rec'>Please record your answer</p>               
                </>
            }

        </div>
    );
}

export default Recorder;