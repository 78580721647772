import { useEffect, useState } from "react";

import { useDispatch, useSelector } from 'react-redux'
import { addAnswer, uploadAnswerRecording } from '../features/answer'

import Recorder from "./Recorder";
import LoadingSpinner from './LoadingSpinner'

import parse from 'html-react-parser';

const Questions = ({ questions, setEndQuiz, setShowQuestions }) => {
    const dispatch = useDispatch()

    const userId = useSelector(state => state.user.user.id)
    const loading = useSelector(state => state.answer.loading)

    const [currentQuestion, setCurrentQuestion] = useState(0);
    // const [isLastQuestion , setIsLastQuestion] = useState(false);
    const [error, setError] = useState('');

    const [blob, setBlob] = useState('');
    const [blobURLInternal , setBlobInternalURL] = useState(null);


    const handleAnswerOptionClick = () => {
        const nextQuestion = currentQuestion + 1;
        var isLastQuestion = false;
        if (nextQuestion >= questions.length) {
            isLastQuestion = true;
        }
        if(blob){
            //make a post request to the backend to make an answer
            dispatch(addAnswer({
                questionId: questions[currentQuestion].id,
                userId: userId,
                questionType: questions[currentQuestion].type,
                questionText: questions[currentQuestion].questionTitle,
                isLastQuestion: isLastQuestion,
                jobType: questions[currentQuestion].jobType,
            })).then((res) => {
                if(res.payload){
                    //make a put request to the backend to add the recording to the answer
                    dispatch(uploadAnswerRecording({
                        answerId: res.payload.id,
                        recording: blob
                    })).then((res) => {
                        if(res.payload){
                            setBlob('');
                            setBlobInternalURL(null);
                            if (nextQuestion < questions.length) {
                                setCurrentQuestion(nextQuestion);
                            } else {
                                setShowQuestions(false)
                                setEndQuiz(true)
                            }
                        } else {
                            setError('Error uploading recording')
                        }
                    })
                } else {
                    setError('Error creating answer')
                }
            });
            //clear the recorder
        } else {
            alert('Please record your answer');
        }
    };

    return (
        <div className="question">
            <div className='question-section'>
                <div className='question-count'>
                    <span>Question {currentQuestion + 1}</span> of {questions.length}
                </div>
                <div className='question-type'>
                    <span>{questions[currentQuestion].type == 'READING' ? 
                        <>Record yourself reading this text out loud.</> 
                        :
                        <>Record yourself answering these questions. Speak as much as possible:
                        </>
                        }</span>
                </div>
                <div className='question-text'>{parse(questions[currentQuestion].questionTitle)}</div>

                <Recorder setBlob={setBlob} blobURLInternal={blobURLInternal} setBlobInternalURL={setBlobInternalURL} />
                {loading ? 
                    <LoadingSpinner /> : 
                    <button className="answer_submit" onClick={() => handleAnswerOptionClick()}> Answer </button>
            }
                <p className="error">{error}</p>
            </div>
        </div>
    );
}

export default Questions;