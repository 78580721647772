import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import API from "../api"

export const addAnswer = createAsyncThunk(
    "answer/addAnswer",
    async (dispatch, thunkAPI) => {
        try {
            return API.post(`answers` ,{
                "QuestionId": dispatch.questionId,
                "UserId": dispatch.userId,
                "QuestionType": dispatch.questionType,
                "QuestionText": dispatch.questionText,
                "JobType": dispatch.jobType,
            }).then((response) => {
                if (response.status == 200) {
                    return response.data
                } else {
                    return thunkAPI.rejectWithValue(response.data)
                }
            })
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const uploadAnswerRecording = createAsyncThunk(
    "answer/uploadAnswerRecording",
    async (dispatch, thunkAPI) => {
        const formData = new FormData();
        formData.append('answerSound', dispatch.recording);
            try {
                return API.put(`answers/addRecording/${dispatch.answerId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    if (response.status == 200) {
                        return response.data
                    } else {
                        return thunkAPI.rejectWithValue(response.data)
                    }
                })
            } catch (e) {
                return thunkAPI.rejectWithValue(e)
            }
    }
)

const answerSlice = createSlice({
    name: "answer",
    initialState: {
        answer: [],
        loading: false,
        error: null,
    },
    extraReducers: {
        [addAnswer.pending]: (state, action) => {
            state.loading = true
        },
        [addAnswer.fulfilled]: (state, action) => {
            state.loading = false
            state.answer = action.payload
        },
        [addAnswer.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },

        [uploadAnswerRecording.pending]: (state, action) => {
            state.loading = true
        },
        [uploadAnswerRecording.fulfilled]: (state, action) => {
            state.loading = false
            state.answer = action.payload
        },
        [uploadAnswerRecording.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export default answerSlice.reducer
