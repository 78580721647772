import { configureStore } from '@reduxjs/toolkit';

import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';

import thunk from 'redux-thunk';

import companyReducer from "./features/company"
import userReducer from "./features/user"
import answerReducer from "./features/answer"

import storage from 'redux-persist/lib/storage';

const reducers = combineReducers({
  company: companyReducer,
  user: userReducer,
  answer: answerReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'users/logout/fulfilled') {
    state.user = undefined
  }
  return reducers(state, action)
}

const persistConfig = {
  key: 'root',
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({

  reducer: persistedReducer,

  devTools: true,

  middleware: [thunk],

});

export default store;