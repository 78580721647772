const EndQuizScreen = () => {

    return (
        <div className="quiz-end">
            <h1>Thank you!</h1>
            <p>You will receive your results via email.</p>
        </div>
    );
}

export default EndQuizScreen;